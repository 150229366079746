import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import useBreakpoints from '@utils/useBreakpoints';
import SectionTitle from '@components/common/SectionTitle';
import CenteredSectionContainer from '@components/common/CenteredSectionContainer';
import useStyles from './styles';

const ProjectResponsibilitiesSection = ({ responsibilities }) => {
  const classes = useStyles();
  const { smDown } = useBreakpoints();

  const listElementsForMobileOrTablet = (
    <Typography variant="body2" align="center">
      {responsibilities.map((responsibility, index) => (
        (index === responsibilities.length - 1) ? responsibility.value : `${responsibility.value} / `
      ))}
    </Typography>
  );

  const listElementsForDesktop = (
    <Grid container>
      {responsibilities.map((responsibility) => (
        <Grid item md={4} key={responsibility} align="center" className={classes.gridItem}>
          <Typography variant="body2">
            { responsibility }
          </Typography>
        </Grid>
      ))}
    </Grid>
  );

  return (
    <CenteredSectionContainer>
      <SectionTitle>
        WHAT WE’VE DONE
      </SectionTitle>
      {smDown
        ? listElementsForMobileOrTablet
        : listElementsForDesktop}
    </CenteredSectionContainer>
  );
};

ProjectResponsibilitiesSection.propTypes = {
  responsibilities: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ProjectResponsibilitiesSection;

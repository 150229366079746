import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  borderWrapper: {
    display: 'flex',
  },
  border: (props) => ({
    width: '10px',
    minWidth: '10px',
    background: `linear-gradient(180deg, ${props.gradientColorLight} 0%, ${props.gradientColorDark} 100%)`,
    [theme.breakpoints.down('sm')]: {
      width: '8px',
      minWidth: '8px',
    },
  }),
  textContainer: {
    paddingLeft: '40px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '32px',
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '22px',
    },
  },
  message: {
    fontStyle: 'italic',
    color: '#666666',
  },
  organization: {
    marginTop: '3rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '2rem',
    },
  },
}));

export default useStyles;

import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import CenteredSectionContainer from '@components/common/CenteredSectionContainer';
import useStyles from './styles';

const ProjectTestimonialSection = ({
  message,
  organization,
  name,
  gradientColorLight,
  gradientColorDark,
}) => {
  const classes = useStyles({ gradientColorLight, gradientColorDark });

  return (
    <CenteredSectionContainer>
      <div className={classes.borderWrapper}>
        <div className={classes.border} />
        <div className={classes.textContainer}>
          <Typography variant="body1" className={classes.message}>
            { message }
          </Typography>
          <Typography variant="h4" className={classes.organization}>
            { organization }
          </Typography>
          <Typography variant="h4" className={classes.name}>
            { name }
          </Typography>
        </div>
      </div>
    </CenteredSectionContainer>
  );
};

ProjectTestimonialSection.propTypes = {
  message: PropTypes.string.isRequired,
  organization: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  gradientColorLight: PropTypes.string.isRequired,
  gradientColorDark: PropTypes.string.isRequired,
};

export default ProjectTestimonialSection;

import React from 'react';
import PropTypes from 'prop-types';
import Layout from '@layouts/default';
import SEO from '@components/meta/SEO';
import ProjectHeader from '@components/project-detail/ProjectHeader';
import ProjectPrimaryImage from '@components/project-detail/ProjectPrimaryImage';
import ProjectIntroSection from '@components/project-detail/ProjectIntroSection';
import ProjectPrimaryVideoSection from '@components/project-detail/ProjectPrimaryVideoSection';
import ProjectImageGallerySection from '@components/project-detail/ProjectImageGallerySection';
import ProjectChallengesSection from '@components/project-detail/ProjectChallengesSection';
import ProjectTestimonialSection from '@components/project-detail/ProjectTestimonialSection';
import ProjectResponsibilitiesSection from '@components/project-detail/ProjectResponsibilitiesSection';
import ShareThisPage from '@components/common/ShareThisPage';
import FeaturedProjectsSection from '@components/common/FeaturedProjectsSection';
import ContactBannerSection from '@components/common/ContactBannerSection';
import SectionSpacer from '@components/common/SectionSpacer';

const ProjectDetailPage = ({ pageContext }) => {
  const { content } = pageContext;

  return (
    <Layout>
      <SEO title={content.title} />
      <ProjectHeader
        title={content.title}
        date={content.publishDate}
        subtitle={content.subtitle}
        backgroundImage={content.headerBackgroundImage.fluid}
      />
      <ProjectPrimaryImage
        image={content.primaryImage.fluid}
        gradientColorLight={content.gradientColorLight}
        gradientColorDark={content.gradientColorDark}
      />
      <SectionSpacer xs="30px" />
      <ProjectIntroSection
        headline={content.introHeading}
        body={content.introBody.introBody}
      />
      <SectionSpacer />
      {content.primaryVideo
        ? (
          <>
            <ProjectPrimaryVideoSection
              videoId={content.primaryVideo}
            />
            <SectionSpacer />
          </>
        ) : null}
      <ProjectImageGallerySection
        images={content.imageGallery}
      />
      <SectionSpacer />
      {content.challenges
        ? (
          <>
            <ProjectChallengesSection
              challenges={content.challenges}
              gradientColorLight={content.gradientColorLight}
              gradientColorDark={content.gradientColorDark}
            />
            <SectionSpacer sm="0" xs="0" />
          </>
        ) : null}
      {content.testimonial
        ? (
          <>
            <ProjectTestimonialSection
              message={content.testimonial.message.message}
              organization={content.testimonial.organization}
              name={content.testimonial.name}
              gradientColorLight={content.gradientColorLight}
              gradientColorDark={content.gradientColorDark}
            />
            <SectionSpacer />
          </>
        ) : null}
      {content.responsibilities
        ? (
          <>
            <ProjectResponsibilitiesSection
              responsibilities={content.responsibilities}
            />
            <SectionSpacer />
          </>
        ) : null}
      <ShareThisPage label="Share Project" />
      <SectionSpacer />
      <FeaturedProjectsSection
        title="RELATED PROJECTS"
        projects={content.relatedProjects}
      />
      <ContactBannerSection />
    </Layout>
  );
};

ProjectDetailPage.propTypes = {
  pageContext: PropTypes.object.isRequired, // eslint-disable-line
};

export default ProjectDetailPage;
